<template>
  <section v-if="!loading" v-loading="saving">
    <el-header>
      <el-col :span="16">
        <el-page-header @back="cancel" :content="isNew ? 'Novo produto' : `${product.name}`"></el-page-header>
      </el-col>

      <el-col :span="8" style="text-align: right">
        <el-button type="primary" @click="save" :disabled="!canSave">{{ 'Salvar' }}</el-button>
      </el-col>
    </el-header>

    <el-form ref="form" :model="product" :rules="rules" label-width="160px">
      <!-- ID -->
      <el-form-item label="ID" prop="id">
        <el-input v-model="product.id" disabled></el-input>
      </el-form-item>

      <!-- ID From Supplier -->
      <el-form-item label="ID no fornecedor" prop="idFromSupplier">
        <el-input v-model="product.idFromSupplier"></el-input>
      </el-form-item>

      <!-- Barcode -->
      <el-form-item label="Códigos de barra" prop="barcodes">
        <el-input @keyup.enter.native="addBarcode" v-model="barcodeInput">
          <el-button
            slot="append"
            icon="el-icon-plus"
            @click="addBarcode"
          ></el-button>
        </el-input>

        <div class="barcodes-container">
          <div v-if="product.barcodes && product.barcodes.length > 0">
            <el-tag
              v-for="(barcode, index) in product.barcodes"
              class="spaced"
              type="primary"
              :key="barcode"
              :disable-transitions="true"
            >
              {{ barcode }}
              <i
                class="el-tag__close el-icon-close"
                @click.stop="removeBarcode(index)"
              />
            </el-tag>
          </div>
          <div v-else>
            <div class="no-barcodes">Nenhum código registrado</div>
          </div>
        </div>
      </el-form-item>

      <!-- Name -->
      <el-form-item label="Nome Fiscal" prop="name">
        <el-input v-model="product.name"></el-input>
      </el-form-item>

      <!-- Display Name -->
      <el-form-item label="Nome de Exibição" prop="displayName">
        <el-input v-model="product.displayName"></el-input>
      </el-form-item>

      <!-- Description -->
      <el-form-item label="Descrição" prop="description">
        <el-input type="textarea" v-model="product.description"></el-input>
      </el-form-item>

      <!-- Brand -->
      <el-form-item label="Marca" prop="brandId">
        <snowflake-remote-search-select
          v-model="product.brandId"
          placeholder="Marca"
          :search-function="productBrandService.search"
          :find-function="productBrandService.getProductBrandById"
          :value-function="(item) => item.id"
        >
        </snowflake-remote-search-select>
      </el-form-item>

      <!-- Base Score -->
      <el-form-item label="Score Base" prop="baseScore">
        <el-input-number
          v-model="product.baseScore"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched"
          style="text-align: left"
        >
        </el-input-number>
      </el-form-item>

      <!-- Cost -->
      <el-form-item label="Custo" prop="cost">
        <el-input-number
          v-model="product.cost"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched"
          style="text-align: left"
        >
        </el-input-number>
      </el-form-item>

      <!-- Market Price -->
      <el-form-item label="Preço de mercado" prop="marketPrice">
        <el-input-number
          v-model="product.marketPrice"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched"
          style="text-align: left"
        >
        </el-input-number>
      </el-form-item>

      <!-- Perceived Value -->
      <el-form-item label="Valor Percebido" prop="perceivedValue">
        <el-input-number
          v-model="product.perceivedValue"
          controls-position="right"
          :precision="2"
          :step="0.1"
          :min="0"
          class="stretched"
          style="text-align: left"
        >
        </el-input-number>
      </el-form-item>

      <!-- Traits -->
      <el-form-item label="Traits" prop="traits">
        <snowflake-tag-select
          v-model="product.traits"
          :search-function="traitService.search"
          :id-function="(item) => item"
          :label-function="(item) => item"
          :value-function="(item) => item"
        >
        </snowflake-tag-select>
      </el-form-item>

      <!-- Dependent products -->
      <el-form-item label="Deve ser enviado com" prop="dependsOnProducts">
        <snowflake-tag-select
          v-model="product.dependsOnProducts"
          remote
          :search-function="productService.search"
          :find-function="productService.getProductById"
          :id-function="(item) => item.id"
          :label-function="(item) => item.name"
          :value-function="(item) => item.id"
          placeholder="Adicione um produto"
          no-tag-text="Nenhum produto selecionado"
        >
        </snowflake-tag-select>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import Storage from '@/storage';

import ProductBrandService from '@/services/stock/ProductBrandService';
import ProductService from '@/services/stock/ProductService';
import TraitService from '@/services/stock/TraitService';

import SnowflakeRemoteSearchSelect from '@/components/input/RemoteSearchSelect.vue';
import SnowflakeTagSelect from '@/components/input/TagSelect.vue';

export default {
  name: 'product-detail',
  components: {
    SnowflakeRemoteSearchSelect,
    SnowflakeTagSelect,
  },
  data() {
    return {
      rules: {
        externalId: [{ required: true, trigger: 'blur' }],
        name: [{ required: true, trigger: 'blur' }],
        displayName: [{ required: true, trigger: 'blur' }],
        brandId: [{ required: true, trigger: 'blur' }],
        baseScore: [{ required: true, trigger: 'blur' }],
        cost: [{ required: true, trigger: 'blur' }],
        marketPrice: [{ required: true, trigger: 'blur' }],
        perceivedValue: [{ required: true, trigger: 'blur' }],
      },
      loading: true,
      saving: false,
      product: { barcodes: [] },
      barcodeInput: null,
      productBrandService: ProductBrandService,
      productService: ProductService,
      traitService: TraitService,
    };
  },
  async created() {
    if (this.$route.name === 'product-new') {
      this.product = { barcodes: [] };
      this.loading = false;

      return;
    }

    const product = await ProductService.getProductById(this.$route.params.id);

    this.product = this.interfaceProduct(product);
    this.loading = false;
  },
  computed: {
    isNew() {
      return this.$route.name === 'product-new';
    },
    canSave() {
      const roles = this.authRoles();

      return roles.includes('admin') || roles.includes('owner') || roles.includes('financial');
    },
  },
  methods: {
    authRoles() {
      const encodedRole = Storage.get(Storage.keys.auth.role);
      const encodedRoles = Storage.get(Storage.keys.auth.roles);

      return encodedRoles ? JSON.parse(atob(encodedRoles)) : (encodedRole ? [atob(encodedRole)] : null);
    },
    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      this.saving = true;

      if (this.$route.name === 'product-new') {
        const product = await this.create();
        this.$router.push({
          name: 'product-detail',
          params: { id: product.id },
        });

        this.product = this.interfaceProduct(product);
        this.saving = false;
      } else {
        const product = await this.update();

        this.product = this.interfaceProduct(product);
        this.saving = false;
      }

      this.$message.success('Salvo com sucesso.');
    },
    cancel() {
      this.$router.back();
    },
    create() {
      return ProductService.create(this.apiProduct());
    },
    update() {
      return ProductService.update(this.product.id, this.apiProduct());
    },
    apiProduct() {
      const product = Object.assign({}, this.product);
      product.baseScore = Math.round(product.baseScore * 100);
      product.cost = Math.round(product.cost * 100);
      product.perceivedValue = Math.round(product.perceivedValue * 100);
      product.marketPrice =  Math.round(product.marketPrice * 100);

      return product;
    },
    interfaceProduct(apiProduct) {
      const product = Object.assign({}, apiProduct);
      product.baseScore /= 100;
      product.cost /= 100;
      product.perceivedValue /= 100;
      product.marketPrice /= 100;

      return product;
    },
    removeBarcode(index) {
      this.product.barcodes.splice(index, 1);
    },
    addBarcode() {
      if (!this.product.barcodes) {
        this.product.barcodes = [];
      }

      if (this.product.barcodes.indexOf(this.barcodeInput) >= 0) {
        this.$message.warning('Código de barras já inserido.');
      } else {
        this.product.barcodes.push(this.barcodeInput);
      }

      this.barcodeInput = null;
    },
  },
};
</script>

<style lang="scss" scoped>
div.barcodes-container {
  text-align: left;
  border: #c1c1c1 dashed 1.5px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;

  div.no-barcodes {
    text-align: center;
    font-size: 12;
    line-height: 40px;
    color: #5e7382;
  }

  .el-tag {
    margin: 4px;
  }
}
</style>
