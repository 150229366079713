<template>
  <section class="product-list">
    <snowflake-search-list context="product" :service="productService">
      <template slot="tableRows">

        <!-- ID -->
        <el-table-column prop="id" :label="$t('product.id')" width="80" sortable></el-table-column>

        <!-- Display Name -->
        <el-table-column prop="displayName" label="Nome Exibição" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Name -->
        <el-table-column prop="name" label="Nome Fiscal" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Brand Name -->
        <el-table-column prop="brandName" label="Fornecedor" :show-overflow-tooltip="true" sortable></el-table-column>

        <!-- Cost -->
        <el-table-column prop="cost" :label="$t('product.cost')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.cost / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

        <!-- Market Price -->
        <el-table-column prop="marketPrice" :label="$t('product.marketPrice')" sortable>
          <template slot-scope="scope">
            <span style="margin-left: 10px">R$&nbsp;{{ (scope.row.marketPrice / 100).toFixed(2) }}</span>
          </template>
        </el-table-column>

      </template>
    </snowflake-search-list>

  </section>
</template>

<script>
import ProductService from '@/services/stock/ProductService';
import SnowflakeSearchList from '@/components/list/SearchList.vue';

export default {
  name: 'product-list',
  components: {
    SnowflakeSearchList,
  },
  data() {
    return {
      productService: ProductService,
    };
  },
};
</script>
